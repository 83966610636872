const zh = {
  home: "首页",
  photo_printer: "照片打印机",
  laser_printer: "激光打印机",
  service_support: "服务与支持",
  about_us: "关于我们",
  concat_us: "联系我们",
  back_top: "回到顶部",
  product_1: "极印激光打印一体机",
  product_2: "极印口袋照片打印机",
  product_3: "极印激光打印系列",
  product_4: "极印留声照片打印机",
  product_5: "极印口袋照片打印机N1",
  procuct_6: "极印黑白激光打印机",
  procuct_7: "极印黑白激光一体机",
  procuct_8: "极印·立可印 潮玩口袋照片打印机",
  procuct_9: "极印手机照片打印机",
  procuct_10: "极印·立可印N1 潮玩口袋照片打印机",
  procuct_11: "极印照片打印机",
  procuct_12: "极印激光打印机",
  XP356DNL: "XP356DNL",
  slogan_1: "更年轻 更能打",
  slogan_2: "全能高效",
  slogan_3: "年度好物",
  slogan_4: "新品上市",
  slogan_5: "口碑产品",
  slogan_6: "好照片，因你而珍贵",
  slogan_6_1: "好照片",
  slogan_6_2: "因你而珍贵",
  slogan_7: "专注每一页，办公更轻松",
  slogan_7_1: "专注每一页",
  slogan_7_2: "办公更轻松",
  slogan_8: "照片玩家的私藏好物，在家实现写真自由",
  slogan_9: "随拍随印，印出生活小确幸",
  button_1: "了解详情 >",
  data_tip:
    "*以上数据均来自汉图实验室测试结果，使用过程中会因实际使用环境和条件或其他因素而存在差异。",
  photo: {
    customer_praise: "用户好评",
    slogan: "即拍即印，日子有声有色，照片玩家的私藏好物，在家实现写真自由",
    spec_1: "热升华打印 | 6寸高清画质 | 自动覆膜 | AR留声照片",
    spec_2: "内含5张相纸，彩色黑白皆可印",
    spec_3: "无墨打印 | 三寸背胶相纸 | AR留声照片",
    text_1: "实质照片更有意义",
    text_2: "极印照片打印机印下我们最爱的瞬间，是属于这个夏天极致的浪漫!",
    text_3: "手帐照片神器，随时随地定格美好瞬间",
    text_4:
      "极印让我惊喜的是它能够打印视频、音频照片，用手机扫一扫就能看到AR效果，太好玩了!",
    text_5:
      "每一次翻开相册，看到那些熟悉的面面，仿佛能感受到那份纯真的美好。极印照片打印机便能将这份美好复刻出来!",
    text_6:
      "有点像不废片的拍立得~小小一个但是功能挺多，以后有极印照片打印机就可以实现自印DIY自由啦!",
    text_7: "可以打印视频的极印照片打印机也泰酷辣!!!",
    text_8: "打印动漫很好看!非常适合二次元手帐!",
    text_9: "照片是有温度的，每次看到熟悉的面面，就会把思绪拉回拍照时的那一天!",
    text_10: "生活也太幸福了/极印口袋照片打印机，手帐神器",
  },
  laser: {
    loonson_series: "龙芯系列",
    loonson_spec:
      "搭载国内首个打印机主控芯片-龙芯2P0500；100%自主知识产权，国内研发、设计、生产；打印速度高达35-40页/分钟",
    loonson_spec_1: "搭载国内首个打印机主控芯片-龙芯2P0500",
    loonson_spec_2: "100%自主知识产权，国内研发、设计、生产",
    loonson_spec_3: "打印速度高达35-40页/分钟",
    highspeed_series: "高速系列",
    highspeed_spec:
      "高效全能，首页打印 < 4.5秒，打印速度高达35页每分钟，双面打印速度高达32面每分钟",
    highspeed_spec_1: "高效全能，首页打印 < 4.5秒，打印速度高达35页每分钟",
    highspeed_spec_2: "双面打印速度高达32面每分钟",
    flagship_series: "旗舰系列",
    flagship_spec:
      "高速节能，打印速度高达40页每分钟，双面打印速度高达32面每分钟；使用薄膜定影何陶瓷片定向加热技术，升温效率提升64%",
    flagship_spec_1:
      "高速节能，打印速度高达40页每分钟，双面打印速度高达32面每分钟",
    flagship_spec_2: "使用薄膜定影何陶瓷片定向加热技术，升温效率提升64%",
    consumables: "耗材",
    product_details: "产品详情",
    functional: "功能参数",
    printer_consumables: "适配耗材",
    models: "适配机型",
    ppm: "页/分钟",
    ipm: "面/分钟",
    label_1: "机型名称",
    label_2: "打印速度",
    label_3: "平板扫描、复印",
    label_4: "自动送稿器",
    label_5: "国产操作系统",
    label_6: "国产芯片",
    label_7: "极印APP 轻松地安装和管理打印机",
    label_8: "双面打印速度",
    label_9: "无线网络",
    full_video: "完整视频",
  },
  service: {
    slogan_1: "专业 · 及时 · 高效",
    slogan_2: "致力于为每一位用户提供优质的服务",
    software_download: "软件下载",
    knowledge_base: "产品知识库",
    service_policy: "服务政策",
    after_sales: "申请售后",
    contact_text_1: "选择您喜欢的方式，随时随地与我们联系",
    contact_text_2: "选择您喜欢的方式，随时随地与我们联系",
    download_app: "App下载（Android/iOS）",
    download_ios: "App下载（iOS）",
    download_android: "App下载（Android）",
    download_driver: "驱动下载",
    win_driver: "Windows驱动下载",
    linux_driver: "Linux驱动下载",
    faq: "常见问题",
    instructional_videos: "操作视频",
    product_overview: "产品介绍",
    operating_guide: "操作指南",
    refill_paper: "补充相纸",
    paper_error: "取纸出错",
    ar_photos: "AR照片",
    unboxing: "新机开箱",
    usb_connection: "电脑USB线连接打印机安装驱动",
    adding_printer: "电脑无线添加自动获取网络IP地址的打印机",
    product_manual: "产品说明书",
    battery_version: "电池版",
    power_version: "电源版",
    preview: "预览",
    download: "下载",
    more: "更多问题",
    more_video: "更多视频",
    view: "点击查看",
  },
  about: {
    text_1: "平平无奇 也想记录下来的生活",
    text_2: "五颜六色的生活 不能乱七八糟地过",
    text_3: "喜欢镜头里的每一个故事",
    text_4: "奔赴事业的道路 每一印都给力",
    text_5: "用极印 让你的加班时间越来越短",
    text_6: "人已回归工位 迎接泼天富贵",
    text_7:
      "极印，一个充满活力与创意的打印机品牌，自2017年起，便以“更年轻，更能打”为理念，走进我们的生活。",
    text_7_1: "极印",
    text_7_2: "一个充满活力与创意的打印机品牌。",
    text_7_3: "自2017年起，",
    text_7_4: "便以“更年轻，更能打”为理念，走进我们的生活。",
    text_8:
      "极印的品牌标识由品牌首字母JY组成，图形设计灵感来源于打印出来的打印纸张。倾斜向上的45度角，给人带来年轻的感觉。寓意着年轻与无限可能。",
    text_8_1: "极印的品牌标识由品牌首字母JY组成，",
    text_8_2: "图形设计灵感来源于打印出来的打印纸张。",
    text_8_3: "倾斜向上的45度角，给人带来年轻的感觉。",
    text_8_4: "寓意着年轻与无限可能。",
    text_9:
      "极印照片打印产品始终陪伴用户以轻松、愉悦的方式记录生活，希望每位用户都能在日常影像记录中，找到人生丰富轻盈的仪式感。让每一刻温馨与美好都能轻松定格。",
    text_9_1: "极印照片打印产品始终陪伴用户以轻松、愉悦的方式记录生活，",
    text_9_2: "希望每位用户都能在日常影像记录中，",
    text_9_3: "找到人生丰富轻盈的仪式感。",
    text_9_4: "让每一刻温馨与美好都能轻松定格。",
    text_10: "极印激光打印机，则是你职场上的得力助手，助你轻松应对各种挑战。",
    text_11:
      "在极印的世界里，年轻不仅仅是一种年龄状态，更是一种积极向上的生活态度。它鼓励我们不断突破自我，追求更好的自己。选择极印，就是选择了一种年轻、活力、高效的生活方式。",
    text_11_1: "在极印的世界里，年轻不仅仅是一种年龄状态，",
    text_11_2: "更是一种积极向上的生活态度。",
    text_11_3: "它鼓励我们不断突破自我，追求更好的自己。",
    text_11_4: "选择极印，就是选择了一种年轻、活力、高效的生活方式。",
    event_1: "品牌小事件",
    event_2: "极印品牌诞生，成为首个照片打印机国产品牌",
    event_3: "极印手机照片打印机众筹上市上市半年国内销量第一",
    event_4: "极印留声照片打印机上市，收获众多摄影博主、时尚达人推荐喜爱",
    event_5: "极印 立可印N1潮玩口袋照片打印机上市",
    event_6: "极印激光打印机上市",
    award_1: "品牌荣誉",
    award_2: "Reddot Award 红点设计大奖",
    award_3: "IF Design Award 德国IF工业设计大奖",
    award_4: "Design Intelligence Award 中国设计智造大奖",
    award_5: "Good Design Award 日本GMARK大奖",
    award_6: "Creative设计之都 创意推荐榜",
    award_7: "上海设计100+入围产品",
  },
};

export default zh;
