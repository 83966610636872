const en = {
  home: "Home",
  photo_printer: "Photo Printer",
  laser_printer: "Laser Printer",
  service_support: "Service & Support",
  about_us: "About Us",
  concat_us: "Contact Us",
  back_top: "Back to top",
  product_1: "JiYin MFP Laser Printer",
  product_2: "JiYin Pocket Photo Printer",
  product_3: "JiYin Laser Printer Series",
  product_4: "Jiyin Sound Photo Printer",
  product_5: "Jiyin Pocket Photo Printer N1",
  procuct_6: "JiYin Mono Laser Printer",
  procuct_7: "JiYin Mono All-in-One Laser Printer",
  procuct_8: "Jiyin Pocket Photo Printer",
  procuct_9: "Jiyin Mobile Photo Printer",
  procuct_10: "Jiyin Pocket Photo Printer",
  procuct_11: "JiYin Photo Printer",
  procuct_12: "JiYin Laser Printer",
  XP356DNL: "XP356DNL",
  slogan_1: "More Than Faster",
  slogan_2: "Versatile and Efficient",
  slogan_3: "Best of the Year",
  slogan_4: "New Arrival",
  slogan_5: "Well-reviewed Products",
  slogan_6: "Exceptional Photos, Made Special by You",
  slogan_6_1: "Exceptional Photos",
  slogan_6_2: "Made Special by You",
  slogan_7: "Focus on Every Page, Simplify Your Workflow",
  slogan_7_1: "Focus on Every Page",
  slogan_7_2: "Simplify Your Workflow",
  slogan_8:
    "A must-have for photography enthusiasts, enabling you to print photos freely at home.",
  slogan_9: "Snap and print, capturing life's little joys.",
  button_1: "Learn More",
  data_tip:
    "*The above data are all from Hannto laboratory test results. There may be differences in use due to actual usage environment and conditions or other factors.",
  photo: {
    customer_praise: "Customer Praise",
    slogan:
      "Snap and print anytime, adding color to your memories. A must-have for photography enthusiasts, enabling you to print photos freely at home.",
    spec_1:
      "Dye-Sublimation Printing | 6-Inch High-Definition Quality | Automatic Lamination | AR Audio Photos",
    spec_2:
      "Includes 5 sheets of photo paper, capable of printing in both color and black & white.",
    spec_3: "Inkless Printing | 3-Inch Adhesive Photo Paper | AR Audio Photos",
    text_1: "Tangible Photos Mean More",
    text_2:
      "Jiyin Photo Printer prints our favorite moments, embodying the ultimate romance of this summer!!",
    text_3:
      "My Journaling Photo Companion,capture beautiful moments anytime, anywhere.",
    text_4:
      "What surprised me about the Jiyin printer is its ability to print video and audio photos. Just scan with your phone to see the AR effects—it's so much fun!",
    text_5:
      "Every time you open an album and see those familiar image, you can almost feel the pure joy of those moments.Jiyin Photo Printer can recreate that beauty for you!",
    text_6:
      "It's like a no-waste instant camera! Small in size but packed with features. With the Jiyin Photo Printer, I can enjoy the freedom of DIY printing anytime!",
    text_7: "Jiyin Photo Printer, which can print videos, is super cool!",
    text_8:
      "It's perfect for printing anime, making it ideal for fans of anime-themed journaling!",
    text_9:
      "Photos have a warmth to them; every time you see those familiar scene, you're transported back to the day the picture was taken!",
    text_10:
      "Life is so blissful with the Jiyin Pocket Photo Printer, the ultimate journaling companion!",
  },
  laser: {
    loonson_series: "Loonson Series",
    loonson_spec:
      "Equipped with the first Chinese printer SoC chip-Loongson 2P0500; designed,developed and produced in China, with 100% independent intellectual property rights; print speed up to 35~40ppm",
    loonson_spec_1:
      "Equipped with the first Chinese printer SoC chip-Loongson 2P0500",
    loonson_spec_2:
      "Designed,developed and produced in China, with 100% independent intellectual property rights",
    loonson_spec_3: "Print speed up to 35~40ppm",
    highspeed_series: "High-Speed Series",
    highspeed_spec:
      "Efficient and versatile,first print out time less than 4.5s, print speed up to 35ppm, automatic duplex printing speed up to 32ipm",
    highspeed_spec_1:
      "Efficient and versatile,first print out time less than 4.5s, print speed up to 35ppm",
    highspeed_spec_2: "Automatic duplex printing speed up to 32ipm",
    flagship_series: "Flagship Series",
    flagship_spec:
      "High-speed and energy-efficient, with a printing speed of up to 40 pages per minute and duplex printing speed of up to 32 sides per minute. Utilizes film fusing and ceramic directional heating technology, increasing heating efficiency by 64%",
    flagship_spec_1:
      "High-speed and energy-efficient, with a printing speed of up to 40 pages per minute and duplex printing speed of up to 32 sides per minute",
    flagship_spec_2:
      "Utilizes film fusing and ceramic directional heating technology, increasing heating efficiency by 64%",
    consumables: "Consumables",
    product_details: "Product Details",
    functional: "Functional Parameters",
    printer_consumables: "Compatible Consumables",
    models: "Compatible Models",
    ppm: "ppm",
    ipm: "ipm",
    label_1: "Model Name",
    label_2: "Print Speed",
    label_3: "Flatbed Scanning and Copying",
    label_4: "Automatic Document Feeder (ADF)",
    label_5: "Domestic Operating System",
    label_6: "Domestic Chip",
    label_7: "JiYin App for Easy Printer Installation and Management",
    label_8: "Duplex Print Speed",
    label_9: "Wireless Network",
    full_video: "Full Video",
  },
  service: {
    slogan_1: "Professional · Prompt · Efficient",
    slogan_2: "Committed to providing quality service to every user",
    software_download: "Software Downloads",
    knowledge_base: "Product Knowledge Base",
    service_policy: "Service Policy",
    after_sales: "Request After-Sales Service",
    contact_text_1: "Please contact your sales manager to know more.",
    contact_text_2: "Please select the software that suits you.",
    download_app: "App Download (Android/iOS)",
    download_ios: "App Download (iOS)",
    download_android: "App Download (Android)",
    download_driver: "Driver Download",
    win_driver: "Windows Driver Download",
    linux_driver: "Linux Driver Download",
    faq: "Frequently Asked Questions",
    instructional_videos: "Instructional Videos",
    refill_paper: "Refill Photo Paper",
    paper_error: "Paper Feed Error",
    product_overview: "Product Overview",
    operating_guide: "Operating Guide",
    ar_photos: "AR Photos",
    unboxing: "Unboxing the New Printer",
    usb_connection: "Installing Drivers via USB Connection to Computer",
    adding_printer:
      "Adding a Printer with Auto-Obtained Network IP Address Wirelessly",
    product_manual: "Product Manual",
    battery_version: "Power Adapter Version",
    power_version: "Battery Version",
    preview: "Preview",
    download: "Download",
    more: "More",
    more_video: "More",
    view: "View",
  },
  about: {
    text_1: "Even when life is ordinary, I still want to capture it.",
    text_2: "A colorful life shouldn't be lived in chaos.",
    text_3: "Love every story captured through the lens.",
    text_4: "On the road to career success, every print is perfect.",
    text_5: " Use Jiyin to shorten your overtime hours.",
    text_6: "Return to your desk, and good things will happen.",
    text_7:
      "Jiyin, a vibrant and creative printer brand, has been part of our lives since 2017 with the philosophy of 'More Than Faster'.",
    text_7_1: "Jiyin",
    text_7_2: "a vibrant and creative printer brand,",
    text_7_3: "has been part of our lives since 2017",
    text_7_4: "with the philosophy of 'More Than Faster'.",
    text_8:
      "The Jiyin brand logo is composed of the brand's initials, JY, with a design inspired by printed paper. The 45-degree upward tilt conveys a youthful vibe, symbolizing youth and limitless possibilities.",
    text_8_1: "The Jiyin brand logo is composed of the brand's initials,",
    text_8_2: "JY, with a design inspired by printed paper.",
    text_8_3: " The 45-degree upward tilt conveys a youthful vibe,",
    text_8_4: "symbolizing youth and limitless possibilities.",
    text_9:
      "Jiyin photo printing products consistently accompany users in capturing life's moments in a relaxed and enjoyable way. We hope every user can find a sense of richness and lightness in their everyday photo journaling. Let every warm and beautiful moment be effortlessly preserved.",
    text_9_1:
      "Jiyin photo printing products consistently accompany users in capturing life's moments",
    text_9_2: "in a relaxed and enjoyable way.",
    text_9_3:
      "We hope every user can find a sense of richness and lightness in their everyday photo journaling.",
    text_9_4: "Let every warm and beautiful moment be effortlessly preserved.",
    text_10:
      "Your reliable workplace assistant, helping you effortlessly tackle various challenges.",
    text_11:
      "In the world of Jiyin, youth is not just an age but a positive, forward-looking attitude towards life. It encourages us to continually push our boundaries and strive to be the best version of ourselves. Choosing Jiyin means embracing a lifestyle that is youthful, vibrant, and efficient.",
    text_11_1:
      "In the world of Jiyin, youth is not just an age but a positive,",
    text_11_2: "forward-looking attitude towards life.",
    text_11_3:
      "It encourages us to continually push our boundaries and strive to be the best version of ourselves.",
    text_11_4:
      "Choosing Jiyin means embracing a lifestyle that is youthful, vibrant, and efficient.",
    event_1: "Brand Events",
    event_2:
      "The Jiyin brand was established, becoming the first domestic photo printer brand.",
    event_3:
      "The Jiyin Mobile Photo Printer launched through crowdfunding and became the top-selling product domestically within six months.",
    event_4:
      "Jiyin Sound Photo Printer has launched, receiving recommendations and praise from numerous photography bloggers and fashion influencers.",
    event_5: "Jiyin Pocket Photo Printer N1 has been launched.",
    event_6: "JiYin Laser Printer has been launched.",
    award_1: "Brand Awards",
    award_2: "Reddot Award",
    award_3: "IF Design Award",
    award_4: "Design Intelligence Award",
    award_5: "Good Design Award",
    award_6: "Creative Design Capital-Creative Recommendation List",
    award_7: "Shanghai Design 100+ -Finalist Products",
  },
};

export default en;
