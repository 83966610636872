<template>
  <div>
    <footer class="footer-pc">
      <div class="container footer-content">
        <div class="content-top">
          <div class="jiyin-logo" @click="go('/home')">
            <img v-if="locale === 'zh'" src="../assets/jiyin.svg" />
            <img v-else src="../assets/jiyin_en.png" />
          </div>
          <div v-if="locale === 'zh'" class="media-list">
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 22px"
                src="../assets/tiktok.svg"
                alt="tiktok.svg"
              />
              <img
                class="hover-img"
                src="../assets/tiktok_circle.svg"
                alt="tiktok.svg"
              />
              <div class="code-wrap">
                <img class="code-img" src="../assets/tiktok.jpg" alt="" />
                <i class="code-triangle"></i>
              </div>
            </div>
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 31px"
                src="../assets/wechat.svg"
                alt="wechat.svg"
              />
              <img
                class="hover-img"
                src="../assets/wechat_circle.svg"
                alt="wechat.svg"
              />
              <div class="code-wrap">
                <img class="code-img" src="../assets/wechat.jpg" alt="" />
                <i class="code-triangle"></i>
              </div>
            </div>
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 31px"
                src="../assets/weibo.svg"
                alt="weibo.svg"
              />
              <img
                class="hover-img"
                src="../assets/weibo_circle.svg"
                alt="weibo.svg"
              />
              <div class="code-wrap">
                <img class="code-img" src="../assets/weibo.jpg" alt="" />
                <i class="code-triangle"></i>
              </div>
            </div>
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 41px"
                src="../assets/redbook.svg"
                alt="redbook.svg"
              />
              <img
                class="hover-img"
                src="../assets/redbook_circle.svg"
                alt="redbook.svg"
              />
              <div class="code-wrap">
                <img class="code-img" src="../assets/red_book.jpg" alt="" />
                <i class="code-triangle"></i>
              </div>
            </div>
          </div>
          <div v-else class="media-list">
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 28px"
                src="../assets/facebook.svg"
              />
              <img class="hover-img" src="../assets/facebook_circle.png" />
              <div class="code-wrap">
                <img class="code-img" src="../assets/tiktok.jpg" />
                <i class="code-triangle"></i>
              </div>
            </div>
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 28px"
                src="../assets/x.svg"
              />
              <img class="hover-img" src="../assets/x_circle.png" />
              <div class="code-wrap">
                <img class="code-img" src="../assets/wechat.jpg" />
                <i class="code-triangle"></i>
              </div>
            </div>
            <div class="media-item">
              <img
                class="normal-img"
                style="width: 28px"
                src="../assets/ins.svg"
              />
              <img class="hover-img" src="../assets/ins_circle.png" />
              <div class="code-wrap">
                <img class="code-img" src="../assets/weibo.jpg" />
                <i class="code-triangle"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="locale === 'zh'" class="content-mid">
          <div class="footer-menu">
            <p class="router-text bolder">联系我们</p>
            <router-link class="router-text" to="/service?location=afterSales"
              >联系客服</router-link
            >
            <p class="router-text" onclick="qimoChatClick()">在线咨询</p>
          </div>
          <div class="footer-menu">
            <p class="router-text bolder">商务合作</p>
            <a
              class="router-text"
              href="mailto:business@hannto.com"
              title="business@hannto.com"
              >商务合作</a
            >
          </div>
          <div class="footer-menu">
            <p class="router-text bolder">照片打印机经销商</p>
            <router-link class="router-text" to="/store/photo"
              >线下门店</router-link
            >
            <router-link class="router-text" to="/store/photo-online"
              >线上经销商</router-link
            >
          </div>
          <div class="footer-menu">
            <p class="router-text bolder">激光打印机经销商</p>
            <router-link class="router-text" to="/store/laser"
              >经销商</router-link
            >
          </div>
        </div>
        <div v-else class="content-mid" style="padding-bottom: 0">
          <div class="footer-menu">
            <p class="router-text bolder">{{ $t("concat_us") }}</p>
            <p class="router-text">business@hannto.com</p>
            <p class="router-text">+86 13430497363</p>
            <p class="router-text">
              Room 704, Building 1, Lane 88, Shengrong Road, Pudong New Area,
              Shanghai, China
            </p>
          </div>
        </div>
        <div v-if="locale === 'zh'" class="content-bot">
          <span>上海汉图科技有限公司Copyright Hannto Technology Inc.</span>
          <span
            class="like-link"
            @click="goOutside('https://beian.miit.gov.cn/')"
            >沪ICP备17014357号-6</span
          >
          <img src="../assets/gongan.png" alt="guohui" />
          <span
            class="like-link"
            @click="
              goOutside(
                'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019149'
              )
            "
            >沪公网安备 31011502019149号</span
          >
        </div>
      </div>
      <div v-if="locale === 'zh'" class="full-line"></div>
    </footer>
    <footer class="footer-mobile">
      <div class="back-top-wrap">
        <div class="back-top-item" @click="backTop()">
          <span class="menu-text" style="margin-right: 6px">{{
            $t("back_top")
          }}</span>
          <div class="up-icon">
            <img src="../assets/up.svg" alt="up" />
          </div>
        </div>
      </div>
      <div
        v-if="locale === 'en'"
        class="custom-menu"
        @click="customFold = !customFold"
      >
        <div style="padding: 0 20px">
          <p
            class="custom-menu-title"
            :style="!customFold && { borderBottom: 0 }"
          >
            {{ $t("concat_us") }}
          </p>
        </div>
        <div v-if="customFold" class="custom-menu-icon">
          <img src="../assets/plus.svg" alt="plus" />
        </div>
        <div v-else class="custom-menu-icon">
          <img src="../assets/minus.svg" alt="minus" />
        </div>
        <div v-if="!customFold" class="custom-menu-hidden">
          <p class="custom-menu-child">business@hannto.com</p>
          <p class="custom-menu-child">+86 13430497363</p>
          <p class="custom-menu-child">
            Room 704, Building 1, Lane 88, Shengrong Road, Pudong New Area,
            Shanghai, China
          </p>
        </div>
      </div>
      <FooterMenuItem
        v-if="locale === 'zh'"
        title="联系我们"
        :children="contact"
      />
      <FooterMenuItem
        v-if="locale === 'zh'"
        title="商务合作"
        :children="bussiness"
      />
      <FooterMenuItem
        v-if="locale === 'zh'"
        title="照片打印机经销商"
        :children="photo"
      />
      <FooterMenuItem
        v-if="locale === 'zh'"
        title="激光打印机经销商"
        :children="laser"
      />
      <div v-if="locale === 'zh'" class="media-list">
        <div
          class="normal-img"
          style="width: 12px"
          @click="
            goOutside(
              'https://m.douyin.com/share/user/MS4wLjABAAAAPGNRPgJ-UbZB0PDmWGPkftw4yPtbZ6ZfhPRvRj_Z_ho'
            )
          "
        >
          <img src="../assets/tiktok.svg" alt="抖音" />
        </div>
        <div
          class="normal-img"
          style="width: 18px"
          @click="goOutside('https://weixin.qq.com/r/4z8-J_zEZbEarahE92rx')"
        >
          <img src="../assets/wechat.svg" alt="微信" />
        </div>
        <div
          class="normal-img"
          style="width: 18px"
          @click="goOutside('https://weibo.com/u/6376460799')"
        >
          <img src="../assets/weibo.svg" alt="微博" />
        </div>
        <div
          class="normal-img"
          style="width: 22px"
          @click="
            goOutside(
              'https://www.xiaohongshu.com/user/profile/5bcd512cea69df0001f36e38?xhsshare=CopyLink&appuid=5bcd512cea69df0001f36e38&apptime=1662023271'
            )
          "
        >
          <img src="../assets/redbook.svg" alt="小红书" />
        </div>
      </div>
      <div v-if="locale === 'en'" class="media-list">
        <div class="normal-img" style="width: 16px" @click="goOutside('')">
          <img src="../assets/facebook.svg" />
        </div>
        <div class="normal-img" style="width: 16px" @click="goOutside('')">
          <img src="../assets/x.svg" />
        </div>
        <div class="normal-img" style="width: 16px" @click="goOutside('')">
          <img src="../assets/ins.svg" />
        </div>
      </div>
      <div v-if="locale === 'zh'" class="info-text">
        上海汉图科技有限公司Copyright Hannto Technology Inc.
      </div>
      <div
        v-if="locale === 'zh'"
        class="info-text"
        @click="goOutside('https://beian.miit.gov.cn/')"
      >
        沪ICP备17014357号-6
      </div>
      <div
        v-if="locale === 'zh'"
        class="info-text"
        style="display: flex; align-items: center"
        @click="
          goOutside(
            'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019149'
          )
        "
      >
        <img style="margin-right: 4px" src="../assets/gongan.png" alt="国徽" />
        <span>沪公网安备 31011502019149号</span>
      </div>
    </footer>
  </div>
</template>
<script>
import FooterMenuItem from "./FooterMenuItem.vue";

export default {
  components: {
    FooterMenuItem,
  },
  data() {
    return {
      customFold: true,
      locale: localStorage.getItem("locale") || "zh",
      contact: [
        {
          name: "联系客服",
          path: "/service?location=afterSales",
        },
        {
          name: "在线咨询",
          path: "qimo",
        },
      ],
      bussiness: [
        {
          name: "商务合作",
          path: "email",
        },
      ],
      photo: [
        {
          name: "线下门店",
          path: "/store/photo",
        },
        {
          name: "线上经销商",
          path: "/store/photo-online",
        },
      ],
      laser: [
        {
          name: "经销商",
          path: "/store/laser",
        },
      ],
    };
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    goOutside(path) {
      window.open(path);
    },
    backTop() {
      window.scrollTo({ top: 0 });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-pc {
  padding-bottom: 64px;
  border-top: 1px solid #e6e6e6;
  background-color: var(--jy-color-white);
  .footer-content {
    .content-top {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .jiyin-logo {
        width: 138px;
        img {
          width: 100%;
          display: block;
        }
      }
      .media-list {
        display: flex;
        align-items: center;
        .media-item {
          width: 52px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          cursor: pointer;
          position: relative;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            .normal-img {
              display: none;
            }
            .hover-img {
              display: block;
            }
            .code-wrap {
              display: block;
            }
          }
          .hover-img {
            display: none;
            width: 100%;
          }
          .code-wrap {
            width: 120px;
            height: 120px;
            position: absolute;
            top: -140px;
            display: none;
            .code-img {
              width: 100%;
              height: 100%;
              border: 1px solid #dddddd;
            }
            .code-triangle {
              width: 12px;
              height: 12px;
              position: absolute;
              bottom: -6px;
              left: 50%;
              margin-left: -6px;
              transform: rotate(45deg);
              background-color: #ffffff;
              border-bottom: 1px solid #dddddd;
              border-right: 1px solid #dddddd;
            }
          }
        }
      }
    }
    .content-mid {
      display: flex;
      padding-top: 50px;
      padding-bottom: 100px;
      border-top: 1px solid #e6e6e6;
      .footer-menu {
        flex: 1;
        .router-text {
          display: block;
          cursor: pointer;
          margin-bottom: 28px;
          font-size: var(--jy-font-size-sm);
          color: var(--jy-color-grayer);
          font-weight: 400;
          &:hover {
            opacity: 0.6;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.bolder {
            font-weight: 600;
            cursor: default;
            opacity: 1 !important;
          }
        }
      }
    }
    .content-bot {
      height: 60px;
      display: flex;
      align-items: center;
      border-top: 1px solid #e6e6e6;
      span {
        font-size: var(--jy-font-size-sm);
        color: var(--jy-color-grayer);
        &:first-child {
          margin-right: 10vw;
        }
        &.like-link {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      img {
        margin-left: 16px;
        margin-right: 12px;
      }
    }
  }
  .full-line {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
}
.footer-mobile {
  display: none;
  padding-bottom: 36px;
  background-color: #f9f9f9;
  .back-top-wrap {
    padding: 0 20px;
    .back-top-item {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #d1d1d1;
      .menu-text {
        font-size: 12px;
        color: #191919;
        font-weight: 400;
      }
      .up-icon {
        width: 8px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .media-list {
    display: flex;
    align-items: center;
    padding: 50px 20px;
    .normal-img {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .info-text {
    font-size: 12px;
    color: #262626;
    padding: 0 20px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #262626;
    }
  }
  .custom-menu {
    position: relative;
    .custom-menu-title {
      height: 50px;
      font-size: 12px;
      color: #191919;
      font-weight: 400;
      line-height: 50px;
      border-bottom: 1px solid #d1d1d1;
    }
    .custom-menu-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 10px;
      img {
        width: 100%;
        display: block;
      }
    }
    .custom-menu-hidden {
      padding: 20px;
      font-size: 12px;
      color: #191919;
      font-weight: 400;
      background-color: #ededed;
      p {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer-pc {
    display: none;
  }
  .footer-mobile {
    display: block;
  }
}
</style>
